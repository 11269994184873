import React from "react";
import Container from "./container";

import PageHeader from "../components/news-page-header";
import Content from "../components/news-content";

import * as styles from "../styles/components/news-main.module.css";

const NewsMain = (props) => {

  const {
    title,
    category,
    date,
    content
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {title && (<PageHeader title={title} category={category} date={date} />)}
        {content && (<Content content={content} />)}
      </Container>
    </div>
  );
}

export default NewsMain;
