import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Container from "../components/container";
import Seo from "../components/seo";

import NewsMain from "../components/news-main";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query NewsTemplateQuery($id: String!) {
    news: sanityNews(id: { eq: $id }) {
      id
      title
      publishedOn
      category {
        title
        slug {
          current
        }
      }
      content {
        _key
        _rawContent
        media {
          url
          image {
            ...SanityImage
            alt
            caption
          }
        }
      }
      description
    }
  }
`;

const NewsTemplate = (props) => {
  const { 
    data, 
    errors
  } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const news = (data || {}).news;

  return (
    <Layout>
      <Seo
        title={news.title}
        description={news.description}
      />

      {(news.title || (news.content && news.content.length)) ? <NewsMain title={news.title} category={news.category} date={news.publishedOn} content={news.content} /> : null}
    </Layout>
  );
};

export default NewsTemplate;
