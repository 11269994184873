import React from "react";
import Media from "./news-media";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/news-content.module.css";

const Content = (props) => {

  const {
    content
  } = props;

  return (
    <div className={styles.root}>
      {content.map(row => {
        return (
          <div className={cn("grid", styles.section)} key={row._key}>
            <div className={cn("col-12 col-md-6", styles.media)}>
              {(row.media && (row.media.url || (row.media.image && row.media.image.asset))) && (
                <Media media={row.media} />
              )}
            </div>
            <div className="col-12 col-md-6">
              <div className="content-lg">
                {row._rawContent && (<PortableText blocks={row._rawContent} />)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Content;
