import React, { useState } from "react";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import ReactPlayer from 'react-player';
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/news-media.module.css";

const Media = (props) => {

  const {
    media
  } = props;

  const [playVideo, setPlayVideo] = useState(false);

  function handlePlayerPlay() {
    setPlayVideo(true);
  }

  function handlePlayerPause() {
    setPlayVideo(false);
  }

  return (
    <div className={styles.root}>
      {media.url ? (
        <>
        <div className={styles.videoWrap}>
          {media.image && media.image.asset && (
            <div className={styles.cover}>
              <img
                src={imageUrlFor(buildImageObj(media.image))
                  .auto("format")
                  .url()}
                alt={media.image.alt}
              />
            </div>
          )}
          <div className={styles.action} onClick={handlePlayerPlay} onKeyDown={handlePlayerPlay} role="button" tabIndex={0}></div>
          <div className={cn(styles.player, playVideo && styles.active)}>
            <ReactPlayer 
              url={media.url}
              playing={playVideo}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
        {media.image && media.image.caption && (<div className={styles.caption}>{media.image.caption}</div>)}
        </>
      
      ) : (
        <figure className={styles.image}>
          <img
              src={imageUrlFor(buildImageObj(media.image))
                .auto("format")
                .url()}
              alt={media.image.alt}
            />
          {media.image.caption && (<figcaption className={styles.caption}>{media.image.caption}</figcaption>)}
        </figure>
      )}
    </div> 
  );
};

export default Media;
