import React from "react";
import { getCatUrl } from "../lib/helpers";
import { cn } from "../lib/helpers";
import { Link, navigate } from "gatsby";
import Icon from "./icon";
import { format } from "date-fns";

import * as styles from "../styles/components/news-page-header.module.css";

const PageHeader = (props) => {
  const {
    title,
    category,
    date
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <button className={styles.btnBack} onClick={() => navigate(-1)}>
            <Icon symbol="arrow-left" />Back
          </button>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg">
            {date && (
              <div className={styles.meta}>
                <span className={styles.date}>{format(new Date(date).setDate(new Date(date).getDate() + 1), "MM/dd/yy")}</span>
                <span className={styles.cat}><Link to={getCatUrl(category.slug)}>{category.title}</Link></span>
              </div>
            )}

            <h1 className={cn("h2-lg", styles.title)}>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
